  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:100'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>


      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Description*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-textarea 
            name="Description"
            rows="3" 
            v-model="form.description"
            v-validate="'required|max:250'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Description') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Price*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model.number="form.price" v-validate="'required|min_value:0'" name="Price" />
          <span class="text-danger text-sm">{{ errors.first('Price') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.currency" :reduce="x=>x.code" name="Currency" v-validate="'required'" :options="currencyOptions" />
          <span class="text-danger text-sm">{{ errors.first('Currency') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Period*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" :value="form.period" min="1" max="365" type="number" v-validate="'min_value:1|max_value:365'" name="Period" @input="setPeriod"/>
          <span class="text-danger text-sm">{{ errors.first('Period') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Unit*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.unit" :reduce="x=>x.code" name="Unit" :options="unitOptions" />
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Payment Provider Ref</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.providerReference" v-validate="'max:250'" name="Provider Reference" />
          <span class="text-danger text-sm">{{ errors.first('Provider Reference') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company Link</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.companyId" :reduce="x=>x.code" name="Company" :options="companyOptions" />
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Reward Amount</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model.number="form.rewardAmount" v-validate="'min_value:0'" name="Reward Amount" />
          <span class="text-danger text-sm">{{ errors.first('Reward Amount') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Reward Recurring</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <vs-switch class="" v-model="form.rewardRecurring" />
        </div>
      </div>

      <div class="flex flex-wrap justify-end">
          <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
          <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>
    </vx-card>

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
  },
  props: {
    applicationId: { required: true },
    membershipId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        name: null,
        description: '',
        price: null,
        currency: null,
        period: null,
        unit: null,
        providerReference: null,
        companyId: null,
        rewardAmount: null,
        rewardRecurring: null,
      }),
      currencyOptions: [],
      unitOptions: [
        { code: 1, label: 'Days'},
        { code: 2, label: 'Weeks'},
        { code: 3, label: 'Months'},
        { code: 4, label: 'Years'},
      ],
      companyOptions: [],
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.membershipId == 0 ? 'Add Membership Level' : 'Edit Membership Level'
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('memberships')) {
      this.$router.push('/error-404')
    }

    try {
      this.$vs.loading()
      if (!this.membershipId) {
        const response = await this.$http.get(`api/memberships/apps/${this.applicationId}/formData`)
        this.form.currency = response.data.appDefaultCurrency
        this.currencyOptions = response.data.applicationCurrencies
        this.companyOptions = response.data.companies
      } else {
        const formDataPromise = this.$http.get(`api/memberships/apps/${this.applicationId}/formData`)
        const detailsPromise = this.$http.get(`api/memberships/apps/${this.applicationId}/${this.membershipId}`)
        const [formData, details] = await Promise.all([formDataPromise, detailsPromise])
        this.currencyOptions = formData.data.applicationCurrencies
        this.companyOptions = formData.data.companies
        this.form = new Form({...details.data})
      }

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      if (!this.membershipId) {
        try {
          await this.$http.post(`api/memberships/apps/${this.applicationId}`, this.form.data())
          this.$_notifySuccess('Successfully added membership');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      } else {
        try {
          await this.$http.put(`api/memberships/apps/${this.applicationId}`, this.form.data())
          this.$_notifySuccess('Successfully updated membership');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      }
      
      this.$vs.loading.close();
      this.onCancel()

      
    },
    onCancel () {
      this.$router.push({name: 'application-tools-membership-levels', 
        params: { applicationId: this.applicationId }
      })
    },
    setPeriod (value) {
      this.form.period = value ? parseInt(value) : null
    },
  }
}
</script>

<style>

</style>
